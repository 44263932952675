import "./src/styles/global.css";
import "./src/styles/typography.css";

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    const element = document.querySelector(location.hash);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80, // Offset for sticky headers
        behavior: "smooth",
      });
    }
  }
};
